.fullscreen-icon {
  background-image: url(../../../../shared/images/maximize.png);
  background-size: 26px 26px;
}
.leaflet-retina .fullscreen-icon {
  background-image: url(../../../../shared/images/maximize.png);
  background-size: 26px 26px;
}
/* one selector per rule as explained here : http://www.sitepoint.com/html5-full-screen-api/ */
.leaflet-container:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
}
.leaflet-container:-ms-fullscreen {
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
}
.leaflet-container:full-screen {
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
}
.leaflet-container:fullscreen {
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
}
.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
  z-index: 2;
}
