.leaflet-div-icon {
  background: transparent;
  border: none;
}

.marker-cluster {
  background: #0f0b37;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 50px;
  justify-content: center;
  align-items: center;
  width: 50px;
  display: flex;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 0px;
}
